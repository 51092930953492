@import '../../assets/scss/paper-dashboard/variables';

.header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem
}

.navbar {
  background-color: $navbar-light-primary;
}

.img-logo-responsive {
  max-width: 8vw;
  max-height: 8vh;
}

.header-outline {
  margin: 5px;
  font-size: 0.9em;
}

