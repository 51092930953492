@import '../../assets/scss/paper-dashboard/variables';

.navbar-active {
  background-color: $primary-states-color;
  box-shadow: none;
  border-radius: 5%;
  border-bottom: 1px #ddd;
  text-align: left;
}

.nav-link.root-navlink:hover {
  background-color: $primary-states-color;
  box-shadow: none;
  border-radius: 5%;
  border-bottom: 1px #ddd;
  text-align: left;
}

