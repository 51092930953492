@import '../../assets/scss/paper-dashboard/variables';

.sidebar-header {
  background-color: $sidebar-dark;
}

.sidebar-wrapper {
  background: $sidebar-light;
  background: -moz-linear-gradient(0deg, $sidebar-light 0%, $sidebar-dark 50%);
  background: -webkit-linear-gradient(0deg, $sidebar-light 0%, $sidebar-dark 50%);
  background: linear-gradient(0deg, $sidebar-light 0%, $sidebar-dark 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$sidebar-light, endColorstr=$sidebar-dark, GradientType=1);
}
