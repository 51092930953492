//Override some css styles to fit them in my template
@import "src/assets/scss/paper-dashboard/variables";

.main-panel-root {
   width: 100%;
}

.main-panel-root {
    position: relative;
    float: right;
    width: calc(100%);
    background-color: #ffffff;
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.main-panel-root > .content {
    min-height: calc(100vh - 123px);
    margin-top: 70px;
}

.main-panel-root > .navbar {
    margin-bottom: 0;
}

.main-panel-root .header {
    margin-bottom: 50px;
}

.perfect-scrollbar-on .sidebar,
.perfect-scrollbar-on .main-panel-root {
    //height: 100%;
    //max-height: 100%;
}

.navbar .navbar-nav .nav-item.active .nav-link:not(.btn), .navbar .navbar-nav .nav-item .nav-link:not(.btn):focus,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):hover, .navbar .navbar-nav .nav-item .nav-link:not(.btn):active {
    border-radius: 3px;
    color: $default-color;
}

.navbar.navbar-transparent .nav-item .nav-link:not(.btn) {
    color: $primary-color;
}

.dropdown-menu .dropdown-item {
    color: $white-color;
    font-size: 14px;
    padding: 10px 45px 10px 15px;
    clear: both;
    white-space: nowrap;
    width: 100%;
    display: block;
}
.navbar a.dropdown-item {
    background-color: $white-color;
}


.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
    background-color: $primary-states-color;
}

.fade-enter{
    opacity: 0.5;
}
.fade-exit{
    opacity: 1;
}
.fade-enter-active{
    opacity: 1;
}
.fade-exit-active{
    opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
    transition: opacity 1000ms;
}
