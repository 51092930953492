$theme-colors: (
        "primary":    #1E5631,
        //"secondary":  #ffff00,
        //"success":    $success,
        //"info":       $info,
        //"warning":    $warning,
        //"danger":     $danger,
        //"light":      $light,
        //"dark":       $dark
);

$navbar-light-brand-color: #F16E10;
//$navbar-light-brand-hover-color
//$card-border-color: #1E5631;
@import "~bootstrap/scss/bootstrap";
