@import '../../assets/scss/paper-dashboard/variables';

.md-form {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.md-form > label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.md-form label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  color: #757575;
  cursor: text;
  -webkit-transition: color .2s ease-out, -webkit-transform .2s ease-out;
  transition: color .2s ease-out, -webkit-transform .2s ease-out;
  transition: transform .2s ease-out, color .2s ease-out;
  transition: transform .2s ease-out, color .2s ease-out, -webkit-transform .2s ease-out;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}



.md-form input:not([type])+label::after, .md-form input[type="text"]:not(.browser-default)+label::after, .md-form input[type="password"]:not(.browser-default)+label::after, .md-form input[type="email"]:not(.browser-default)+label::after, .md-form input[type="url"]:not(.browser-default)+label::after, .md-form input[type="time"]:not(.browser-default)+label::after, .md-form input[type="date"]:not(.browser-default)+label::after, .md-form input[type="datetime"]:not(.browser-default)+label::after, .md-form input[type="datetime-local"]:not(.browser-default)+label::after, .md-form input[type="tel"]:not(.browser-default)+label::after, .md-form input[type="number"]:not(.browser-default)+label::after, .md-form input[type="search"]:not(.browser-default)+label::after, .md-form input[type="phone"]:not(.browser-default)+label::after, .md-form input[type="search-md"]+label::after, .md-form textarea.md-textarea+label::after {
  position: absolute;
  top: 65px;
  display: block;
  content: "";
  opacity: 0;
  -webkit-transition: .2s opacity ease-out,0.2s color ease-out;
  transition: .2s opacity ease-out,0.2s color ease-out;
}

.md-form input:not([type]), .md-form input[type="text"]:not(.browser-default), .md-form input[type="password"]:not(.browser-default), .md-form input[type="email"]:not(.browser-default), .md-form input[type="url"]:not(.browser-default), .md-form input[type="time"]:not(.browser-default), .md-form input[type="date"]:not(.browser-default), .md-form input[type="datetime"]:not(.browser-default), .md-form input[type="datetime-local"]:not(.browser-default), .md-form input[type="tel"]:not(.browser-default), .md-form input[type="number"]:not(.browser-default), .md-form input[type="search"]:not(.browser-default), .md-form input[type="phone"]:not(.browser-default), .md-form input[type="search-md"], .md-form textarea.md-textarea {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.md-form .form-control {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}
.md-form .form-control {
  height: auto;
  padding: .6rem 0 .4rem 0;
  margin: 0 0 .5rem 0;
  background-color: transparent;
  border-radius: 0;
}

.md-form input:not([type]):focus:not([readonly]), .md-form input[type="text"]:not(.browser-default):focus:not([readonly]), .md-form input[type="password"]:not(.browser-default):focus:not([readonly]), .md-form input[type="email"]:not(.browser-default):focus:not([readonly]), .md-form input[type="url"]:not(.browser-default):focus:not([readonly]), .md-form input[type="time"]:not(.browser-default):focus:not([readonly]), .md-form input[type="date"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), .md-form input[type="number"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search"]:not(.browser-default):focus:not([readonly]), .md-form input[type="phone"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search-md"]:focus:not([readonly]), .md-form textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid $primary-color;
  -webkit-box-shadow: 0 1px 0 0 $primary-color;
  box-shadow: 0 1px 0 0 $primary-color;
}

.md-form input.is-invalid:not([type]):focus:not([readonly]), .md-form input.is-invalid[type="text"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="password"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="email"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="url"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="time"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="date"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="datetime"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="datetime-local"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="tel"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="number"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="search"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="phone"]:not(.browser-default):focus:not([readonly]), .md-form input.is-invalid[type="search-md"]:focus:not([readonly]), .md-form textarea.is-invalid.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid $danger-color;
  -webkit-box-shadow: 0 1px 0 0 $danger-color;
  box-shadow: 0 1px 0 0 $danger-color;
}

.md-form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.md-form input:not([type]):focus:not([readonly])+label, .md-form input[type="text"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="password"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="email"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="url"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="time"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="date"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="tel"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="number"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="search"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="phone"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="search-md"]:focus:not([readonly])+label, .md-form textarea.md-textarea:focus:not([readonly])+label {
  color: $primary-color;
}
.md-form input.is-invalid:not([type]):focus:not([readonly])+label, .md-form input.is-invalid[type="text"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="password"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="email"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="url"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="time"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="date"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="datetime"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="datetime-local"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="tel"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="number"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="search"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="phone"]:not(.browser-default):focus:not([readonly])+label, .md-form input.is-invalid[type="search-md"]:focus:not([readonly])+label, .md-form textarea.is-invalid.md-textarea:focus:not([readonly])+label {
  color: $danger-color;
}

.card .md-form label {
  font-weight: 300;
}

.md-form>label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.md-form label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  color: #757575;
  cursor: text;
  -webkit-transition: color .2s ease-out,-webkit-transform .2s ease-out;
  transition: color .2s ease-out,-webkit-transform .2s ease-out;
  transition: transform .2s ease-out,color .2s ease-out;
  transition: transform .2s ease-out,color .2s ease-out,-webkit-transform .2s ease-out;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.md-form label {
  font-size: 1rem;
}

.md-form label.active {
  -webkit-transform: translateY(-20px) scale(0.8);
  transform: translateY(-20px) scale(0.8);
  font-size: 1rem;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 4px 4px;
  border-radius: 25px;
  cursor: pointer;
}

.file-preview {
  margin: 0 10px;
}

.img-preview-form {
  text-align: center;
  display: inline-block;
  max-width: 100px;
  max-height: 100px;
}

.file-preview-form {
  text-align: center;
  display: inline-block;
}

.editor-custom {
  min-height: 200px;
  border: 2px solid #ccc;
  padding: 0 10px 0 10px;
}

//Select Wrapper
.select-wrapper {
  position: relative;
}

.select-wrapper .select-dropdown {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select-wrapper ul {
  padding-left: 0;
  list-style-type: none;
}

.dropdown-content li:hover, .dropdown-content li.active {
  background-color: #eee;
}

.select-wrapper .search-wrap {
  display: block;
  padding: 1rem 0 0;
  margin: 0 .7rem;
}

.select-dropdown li.disabled, .select-dropdown li.disabled>span, .select-dropdown li.optgroup {
  color: rgba(0,0,0,0.3);
  cursor: context-menu;
  background-color: transparent !important;
}
.disabled, :disabled {
  pointer-events: none !important;
}

.dropdown-content li {
  width: 100%;
  clear: both;
  line-height: 1.3rem;
  color: #000;
  text-align: left;
  text-transform: none;
  cursor: pointer;
}

.select-wrapper input.select-dropdown {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0;
  margin: 0 0 .94rem 0;
  font-size: 1rem;
  line-height: 2.9rem;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #ced4da;
  outline: 0;
}

.dropdown-content {
  position: absolute;
  z-index: 1021;
  display: none;
  min-width: 6.25rem;
  max-height: 40.625rem;
  margin: 0;
  overflow-y: auto;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  opacity: 0;
  will-change: width,height;
}

.dropdown-primary .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.dropdown-primary .dropdown-content li.active, .dropdown-primary .dropdown-content li a, .dropdown-primary .dropdown-content li span:hover {
  background-color: #4285f4 !important;
}

li.active {
  background-color: #eee;
}

.select-dropdown li.disabled, .select-dropdown li.disabled>span, .select-dropdown li.optgroup {
  color: rgba(0,0,0,0.3);
  cursor: context-menu;
  background-color: transparent !important;
}
.dropdown-content li {
  width: 100%;
  clear: both;
  line-height: 1.3rem;
  color: #000;
  text-align: left;
  text-transform: none;
  cursor: pointer;
}

.dropdown-content li>a, .dropdown-content li>span {
  display: block;
  padding: .5rem;
  font-size: .9rem;
  color: #4285f4;
}
