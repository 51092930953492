.card-user {
  .image {
    //max-height: 130px;
    //background-size: auto;

    //img {
    //  border-radius: 12px;
    //}
  }

  .author {
    text-align: center;
    text-transform: none;
    margin-top: -77px;

    a + p.description {
      margin-top: -7px;
    }
  }

  .avatar {
    width: 124px;
    height: 124px;
    border: 1px solid $white-color;
    position: relative;
  }

  .card-body {
    min-height: 240px;
  }

  hr {
    margin: 5px 15px 15px;
  }

  .card-body + .card-footer {
    padding-top: 0;
  }

  .card-footer {
    h5 {
      font-size: 1.25em;
      margin-bottom: 0;
    }
  }

  .button-container {
    margin-bottom: 6px;
    text-align: center;
  }
}
