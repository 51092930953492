@import '../../assets/scss/paper-dashboard/variables';

.navbar-color {
  background-color: $opacity-gray-3;
  box-shadow: none;
  border-bottom: 1px solid #ddd;

  .nav-link:hover {
    background-color: $primary-states-color;
    box-shadow: none;
    border-radius: 5%;
    border-bottom: 1px #ddd;
    text-align: left;
  }

  a:not(.dropdown-item):not(.btn) {
    color: $white-color;

    &.disabled {
      opacity: .5;
      color: $white-color;
    }
  }
}

.test {
  background-color: $primary-color !important;
}
