//Global Styles
@import "assets/scss/paper-dashboard/variables";

body {
  font-family: $open-sans-family;
}

.box {
  transition: box-shadow .3s;
  width: auto;
  height: auto;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
  float: left;
}

.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.cursor-pointer {
  cursor: pointer;
}

.number-dashboard-circle {
  position: absolute;
  top: -5px;
  right: -20px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding: 2px;
  background-color: $primary-color;
  color: $white-color;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

.number-root-circle {
  //position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  padding: 2px;
  color: $primary-color;
  background-color: $white-color;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

